import React, { useState, useRef, useEffect } from "react"
import Lottie from "react-lottie"
import Beebo2 from "../assets/animation/beebo2.json"
import Coins from "../assets/animation/coins.json"
import LoopTrees from "../assets/images/loop-trees.svg"
import { Waypoint } from "react-waypoint"

const HorizontalScroll = ({ refsHeight }) => {
  const horizontal = useRef(null)
  const [x, setX] = useState(null)
  const windowWidth = useRef(null)

  const [animateCoins, setAnimateCoins] = useState(false)
  const [animateSparks, setAnimateSparks] = useState(false)

  const coins = {
    loop: true,
    autoplay: true,
    animationData: Coins,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const beebo2 = {
    loop: true,
    autoplay: true,
    animationData: Beebo2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => {
    const handleScroll = () => {
      if (windowWidth.current > 991) {
        const totalHeight = Object.values(refsHeight).reduce(
          (total, item) => total + item,
          windowWidth.current - 700
        )

        if (window.scrollY >= totalHeight + window.innerWidth) {
          setX(-window.innerWidth)
        } else if (window.scrollY >= totalHeight) {
          setX(-(window.scrollY - totalHeight))
        }
        if (window.scrollY < totalHeight) {
          setX(0)
        }
      } else {
        setX(0)
      }
    }
    windowWidth.current = window.innerWidth
    window.addEventListener(
      "resize",
      () => (windowWidth.current = window.innerWidth)
    )
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener(
        "resize",
        () => (windowWidth.current = window.innerWidth)
      )
      window.removeEventListener("scroll", handleScroll)
    }
  }, [refsHeight])

  return (
    <div className="section-horizontal-parallax">
      <div
        className="horizontal-wrapper"
        style={{ transform: `translate3d(${x}px, 0, 0)` }}
      >
        <div ref={horizontal} className="horizontal-holder">
          <div className="money-section content-wrapper">
            <div className="content-holder">
              <div className="img-holder">
                <Waypoint onEnter={() => setAnimateCoins(true)} />
                {animateCoins && (
                  <div className="coins">
                    <Lottie
                      options={coins}
                      resizeMode="cover"
                      width={220}
                      height={265}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="content-holder">
              <div className="text-holder">
                <h3 className="title">
                  Part mobile marketplace and part gig hub
                </h3>
                <p>
                  Download the Servbees app to find or create flexible yet
                  rewarding local business opportunities, enjoy seamless
                  transactions, and find convenient solutions to your needs.
                </p>
              </div>
            </div>
          </div>
          <div className="bee-section content-wrapper">
            <div className="content-holder">
              <div className="img-holder">
                <Waypoint onEnter={() => setAnimateSparks(true)} />
                {animateSparks && (
                  <div className="bee">
                    <Lottie
                      options={beebo2}
                      resizeMode="cover"
                      width={150}
                      height={185}
                      style={{ margin: "100px auto" }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="content-holder">
              <div className="text-holder">
                <h3 className="title">
                  Proudly hatched in the Philippines and Singapore{" "}
                </h3>
                <p>
                  In the middle of 2020, Servbees began with a mission to serve
                  and connect "Buzzybees" of every stripe.
                </p>
              </div>
            </div>
          </div>
          <div className="trees">
            <img src={LoopTrees} alt="" />
            <img src={LoopTrees} alt="" />
            <img src={LoopTrees} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HorizontalScroll
